import React, { Component } from "react";

export const FeatureList = () => <div className="feature-list">
<img src="./publish-article.png" alt=""/>
<div className="list-items-gradient"></div>
<div className="list-items-container">
  <ul>
    <li>List and add citations so that you can verify the information and deliver accurate and quality content.</li>
    <li>Rapidly generate relevant content based on your own prompts to produce well-written content that stands out.</li>
    <li>Use the suggestion tool which provides and generate a perfect copy.</li>
    <li>Save content to make your own personalised assignment in few clicks.</li>
  </ul>
</div>
</div>