export const getPromptSuggestionArray = (keyword) => [
  `What are the key technologies and techniques used for ${keyword}? `,
  `How does ${keyword} benefit businesses? `,
  `What challenges does ${keyword} pose for organizations? `,
  `What is the best way to store and analyze large datasets? `,
  `How can ${keyword} be used to create real-time analytics? `,
  `What are the security and privacy concerns associated with ${keyword}? `,
  `What is ${keyword}?`,
  `Define ${keyword}`,
  `What are the advantages of ${keyword}?`,
  `What are the applications of ${keyword}?`,
  `What are the different types of ${keyword}?`,
  `How can ${keyword} be used to improve decision making? `,
  `What are the best practices for managing and analyzing ${keyword}? `,
  `What are the most popular tools used to process ${keyword}? `,
  `What are the most common use cases for ${keyword}?`,
  `What are the key components of a successful ${keyword} strategy?`,
  `What types of data should be included in a ${keyword} system?`,
  `What are the challenges associated with ${keyword} analysis?`,
  `How can ${keyword} be used to drive business decisions?`,
  `What are the benefits of using ${keyword} analytics?`,
  `What are the best practices for managing and storing large amounts of data?`,
  `What are the security and privacy considerations when dealing with ${keyword}?`,
  `What technologies are needed to support a ${keyword} project?`,
  `How can ${keyword} be used to gain valuable insights into customer behavior?`,
  `What tools are available to help manage and analyze ${keyword}?`,
  `What are the major challenges associated with collecting and managing ${keyword}? `,
  `How does ${keyword} provide insights that wouldn't be found with traditional data analysis? `,
  `What are the benefits of using ${keyword} analytics? `,
  `What are the potential applications of ${keyword} in various industries? `,
  `How can organizations ensure that they are compliant with data privacy and security regulations when using ${keyword}? `,
  `What type of technology is needed to collect, store, and analyze ${keyword}? `,
  `What are the best practices for extracting value from ${keyword}? `,
  `What types of data should organizations be collecting to gain the most insights from ${keyword}? `,
  `How can businesses ensure that they are leveraging ${keyword} for competitive advantage? `,
  `How can ${keyword} be used to create predictive models and uncover hidden trends?`,
  `What are the benefits of leveraging ${keyword} for businesses?`,
  `What are the challenges associated with processing ${keyword}?`,
  `How is ${keyword} different from traditional data analysis?`,
  `What are the ethical considerations of using ${keyword}?`,
  `How is ${keyword} being used in the healthcare industry?`,
  `What tools and technologies are needed to manage ${keyword}?`,
  `What are some best practices for collecting and storing ${keyword}?`,
  `What kind of insights can ${keyword} provide to organizations?`,
  `How can organizations protect the privacy of ${keyword}?`,
  `How can ${keyword} be used to drive innovation?`,
  `Introduction to ${keyword}`,
  `Conclusion on ${keyword}`,
  `Overview on ${keyword}`,
  `${keyword} is a term that describes`,
  `${keyword} primarily refers to`,
  `The definition of ${keyword} is`,
  `${keyword} can be described in terms of`,
];

export const allSubjects = [
  "General_A",
  "General_B",
  "General_C",
  "General_D",
  "Access Control",
  "Adv. Computer Networking",
  "Adv. Programming Languages",
  "Application Security",
  "Big Data",
  "Big Data 2",
  "Blockchain & Cryptocurrency",
  "Busi. Continuity & Disaster Plan",
  "Business Ethics",
  "Business Intelligence",
  "Business Research Methods",
  "Business Sustain. & Analysis",
  "C&E",
  "Cloud Computing",
  "Corp strategy,MGMT & Leadership",
  "Cryptography",
  "Cyber Law",
  "Cyber Security",
  "Data Warehouse & Mining",
  "Database Systems",
  "Emerging Threats",
  "Enterprise Risk Management",
  "Ethical Strategic Management",
  "Foundations",
  "Global Policies",
  "Health Finance",
  "Human Computer Usability",
  "ITM Capstone",
  "Info. Security & Risk Mgmt",
  "Information Governance",
  "Information Systems",
  "Initiating the Project",
  "Intro to Data Mining",
  "Legal",
  "Management Info System",
  "Managerial Accounting",
  "Managerial Economics",
  "Managerial Finance",
  "Marketing Communication",
  "Marketing Management",
  "Negotiation",
  "Operation Security",
  "Operational Excellence",
  "Org Behaviour & Leadership",
  "Physical Security",
  "Project Management",
  "Security Architecture",
  "System Analysis and Design",
  "Team Management",
  "Telecom Security",
];

export const recommendedSubjects = ["General", "Big Data", "Database Systems", "Business Intelligence"];