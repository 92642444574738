import React, { Component, useEffect, useState } from "react";
import "./App.css";
import {
  Layout,
  Typography,
  Input,
  Slider,
  InputNumber,
  Row,
  Col,
  Button,
  message,
  AutoComplete,
  Select,
  Modal,
  Form, 
  Tooltip, 
  Space, 
  Dropdown, 
  Menu,
  MenuProps,
  Divider
} from "antd";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { LandingPageHero } from "./components/LandingPageHero";
import { ValueAddDescriptors } from "./components/ValueAddDescriptors";
import { LoginModal } from "./components/LoginModal";
import { FeatureList } from "./components/FeatureList";
import { GeneratedText } from "./components/GeneratedText";
import { GenerateTextDrawer } from "./components/GenerateTextDrawer";
import jwt from "jsonwebtoken";
import { getPromptSuggestionArray, allSubjects, recommendedSubjects } from "./promptSuggestionArray"
import { useCookies } from 'react-cookie';
import { getCookieOptions, getCookie } from './libs/utils/authHelpers';

// import firebase from "firebase";

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyBxITmq5dW-TWklvzaqYsr5TnraODhiMZU",
//   authDomain: "gpt2deployment.firebaseapp.com",
// });

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const { Header, Content, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const FormData = require("form-data");
const { Option } = Select;

const mockSuggestions = [
  "Cons of Big Data Handling?",
  "Advantages of Big Data Handling?",
  "Who invented the term - Big Data?",
  "Nature of Big Data",
  "Cons of Big Data Handling?",
  "Advantages of Big Data Handling?",
  "Who invented the term - Big Data?",
  "Nature of Big Data",
  "What are the uses of Big Data Handling?",
];

export const App = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'refreshToken']);
  
  const recommendations = recommendedSubjects.map((recommend, index) => {
    return {
      label: recommend,
      key: `${index + 1}`,
      icon: <UserOutlined />,
    };
  });

  const subjectList = allSubjects.map((recommend, index) => {
      return {
          label: recommend,
          key: `${recommendations.length + 3 + index}`,
          icon: <UserOutlined />,
      };
  });

  const filetrDropdownValues = [
      {
          label: 'Recommended Subjects',
          disabled: true,
          icon: null,
      },
      ...recommendations,

      { type: 'divider' },
      {
          key: `${recommendations.length + 1}`,
          label: 'All Subjects',
          disabled: true,
      },
      ...subjectList,
  ];

  // const [result, setResult] = useState("");
  const [form] = Form.useForm();
  const [generate, setGenerate] = useState(false);
  const [reGenerate, setReGenerate] = useState(false);
  const [gettingSuggestions, setGettingSuggestions] = useState(false);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userId, setUserId] = useState("");
  
  const [token, setToken] = useState("");
  const [query, setQuery] = useState("");
  const [buttonName, setButtonName] = useState(""); // generate button (subject)
  const [keyword, setKeyword] = useState("");
  
  const [lastUsedQuery, setLastUsedQuery] = useState("");
  const [lastUsedButtonName, setLastUsedButtonName] = useState(""); // generate button (subject)
  const [lastUsedKeyword, setLastUsedKeyword] = useState("");


  const [generatedText, setGeneratedText] = useState("");
  const [querySuggestions, setQuerySuggestions] = useState(null);
  const [references, setReferences] = useState([]);

  const [serpSearchResponse, setSerpSearchResponse] = useState(null);
  const [currentCaretPosition, setCurrentCaretPosition] = useState(0);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [loginPromptReason, setLoginPromptReason] = useState("Login to continue using etutorconnect");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [saveSessionLoading, setSaveSessionLoading] = useState(false);
  const [autoApaButtonLoading, setAutoApaButtonLoading] = useState(false);
  const [plagiarismButtonLoading, setPlagiarismButtonLoading] = useState(false);
  const [refrencesLoading, setRefrencesLoading] = useState(false);
  //Add different models here
  const [filteredNames, setFilteredNames] = useState(filetrDropdownValues);

  useEffect(() => {
    
    if (isAuthenticated) {
      setPageLoading(false);
      return;
    }

    const nonAuthUserFinalizeLoad = () => {
      // clear temp snap as well? For now
      localStorage.removeItem('temp-last-saved-snap');
      setIsAuthenticated(false);
      setPageLoading(false);
    }
    
    const setStateFromLastSession = async (accessToken) => {
      // check if snap id is same as cached snap
      const lastSavedSnapId = localStorage.getItem('last-saved-snap-id');
      const lastSavedSnap = localStorage.getItem('last-saved-snap');
      //load from cache
      if (lastSavedSnap && lastSavedSnapId) {
        const lastSavedSnapParsed = JSON.parse(lastSavedSnap);
        setLastUsedKeyword(lastSavedSnapParsed?.keyword);
        setKeyword(lastSavedSnapParsed?.currentKeyword);
        setQuery(lastSavedSnapParsed?.prompt);
        setButtonName(lastSavedSnapParsed?.subject);
        setLastUsedButtonName(lastSavedSnapParsed?.currentSubject);
        setGeneratedText(lastSavedSnapParsed?.generatedText);
        setIsLandingPage(lastSavedSnapParsed?.isLandingPage);
        setReferences(lastSavedSnapParsed?.referencesHtml);
        if (lastSavedSnapParsed?.serpSearchResponse)
          setSerpSearchResponse(JSON.parse(lastSavedSnapParsed?.serpSearchResponse));
        setPageLoading(false);
        return;
      } else {
        setPageLoading(false);
        return;
      }


      try {
        // from token get userID
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://auth.etutordev.com/api/get-user-details',
          headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${accessToken}`
          }
        };
        
        let res = await axios(config);
        const { id: userDetailsId, name: userDetailsName, email: userDetailsEmail } = res?.data?.data;
        if(!userDetailsId || !userDetailsName || !userDetailsEmail) throw 'Invalid user';

        setEmail(userDetailsEmail);
        setDisplayName(userDetailsName);
        setUserId(userDetailsId);

        // from userID get last snapid
        config = {
          method: 'get',
          url: `https://plagarism-detection-zcd6zrax3q-uc.a.run.app/textgen/users/${userDetailsId}/snaps/latest`,
          headers: { 
            'Authorization': `Bearer ${getCookie("authToken") ?? ""}`, 
            'Content-Type': 'application/json'
          }
        };
    
        res = await axios(config);
        const prevSnapId = res?.data?.last_saved_snap_id;
        // if no saved snap history found load landing page
        if(!prevSnapId) {
          setPageLoading(false);
          return;
        }
        
        // check if snap id is same as cached snap
        const lastSavedSnapId = localStorage.getItem('last-saved-snap-id');
        const lastSavedSnap = localStorage.getItem('last-saved-snap');

        //load from cache
        if (lastSavedSnap && lastSavedSnapId && lastSavedSnapId === prevSnapId) {
          const lastSavedSnapParsed = JSON.parse(lastSavedSnap);
          setLastUsedKeyword(lastSavedSnapParsed?.keyword);
          setKeyword(lastSavedSnapParsed?.currentKeyword);
          setQuery(lastSavedSnapParsed?.prompt);
          setButtonName(lastSavedSnapParsed?.subject);
          setLastUsedButtonName(lastSavedSnapParsed?.currentSubject);
          setGeneratedText(lastSavedSnapParsed?.generatedText);
          setIsLandingPage(lastSavedSnapParsed?.isLandingPage);
          setReferences(lastSavedSnapParsed?.referencesHtml);
          if (lastSavedSnapParsed?.serpSearchResponse)
            setSerpSearchResponse(JSON.parse(lastSavedSnapParsed?.serpSearchResponse));
          setPageLoading(false);
          return;
        }

        // fetch from DB
        config = {
          method: 'get',
          url: `https://plagarism-detection-zcd6zrax3q-uc.a.run.app/textgen/snaps/${prevSnapId}`,
          headers: { 
            'Authorization': `Bearer ${getCookie("authToken") ?? ""}`, 
            'Content-Type': 'application/json'
          }
        };
    
        res = await axios(config);
        const prevSnapData = res?.data;
        if (prevSnapData) {
          setLastUsedKeyword(prevSnapData?.keyword);
          setKeyword(prevSnapData?.currentKeyword);
          setQuery(prevSnapData?.prompt);
          setButtonName(prevSnapData?.subject);
          setLastUsedButtonName(prevSnapData?.currentSubject);
          setGeneratedText(prevSnapData?.generatedText);
          setIsLandingPage(prevSnapData?.isLandingPage);
          setReferences(prevSnapData?.referencesHtml);
          if (prevSnapData?.serpSearchResponse)
            setSerpSearchResponse(JSON.parse(prevSnapData?.serpSearchResponse));
          setPageLoading(false);
          return;
        }

        setPageLoading(false);
      } catch(e) {
        // do nothing
        setPageLoading(false);
      }
    }

    const getNewAuthTokenFromRefreshToken = () => {

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://auth.etutordev.com/api/refresh-token?refresh_token=${refreshToken}`,
        };

        return axios(config)
          .then((response) => {
            const { access_token, refresh_token } = response?.data;
            if(!access_token || !refreshToken) throw 'Refresh failed';
            setCookie('authToken', access_token, getCookieOptions());
            setCookie('refreshToken', refresh_token, getCookieOptions());
            // localStorage.setItem('auth_token', access_token);
            // localStorage.setItem('refresh_token', refresh_token);
            setIsAuthenticated(true);
            setStateFromLastSession(access_token);
          })
          .catch((error) => {
            nonAuthUserFinalizeLoad();
          });
    }

    // let authToken = localStorage.getItem("auth_token") ?? "";
    // let refreshToken = localStorage.getItem("refresh_token") ?? "";
    let authToken = cookies.authToken ?? "";
    let refreshToken = cookies.refreshToken ?? "";

    if(!authToken && !refreshToken) {
      nonAuthUserFinalizeLoad();
      return;
    }

    try {
      // check expiry, if expired try refreshing token else redirect ot login page
      if(!authToken) {
        getNewAuthTokenFromRefreshToken();
        return;
      }
      
      const decodedToken = jwt.decode(authToken, { complete: true });
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        if(!refreshToken) {
          nonAuthUserFinalizeLoad();
        }
        getNewAuthTokenFromRefreshToken();
      } else {
        if (isLandingPage) {
          setStateFromLastSession(authToken).then(() => {
            setIsAuthenticated(true);
            setPageLoading(false);
          }).catch(e => {
            nonAuthUserFinalizeLoad();
          });
        }
      }
    } catch (e) {
      nonAuthUserFinalizeLoad();
    }
    // setPageLoading(false);
  }, []);

  const onChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const showLoginPromptModal = () => {
    setShowLoginPrompt(true);
  };

  const hideLoginPrompt = () => {
    setShowLoginPrompt(false);
  };

  const setMockAuthToken = () => {
    window.location.href = 'https://auth.etutordev.com?url=https://textgen.etutordev.com';
//     setPageLoading(true);
//     setTimeout(() => {
//       setCookie('authToken', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiMTUzZDc2NzJlZTRiNTQ0MDRiNWNhMTVlNGJhMDNkYjU3ZGQxZGY0YzExOTM4MzM3N2M1YTMyMDdhMDQ5MTEwZDE5YjA5Y2E2NDViODVkMDQiLCJpYXQiOjE2Nzk4MjY4NTAuMDk1MzExLCJuYmYiOjE2Nzk4MjY4NTAuMDk1MzE0LCJleHAiOjE2ODExMjI4NTAuMDY0NTk4LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.btcqRRkhoCItFKHmKC9lWYK9xsO9LK8qf4CdhnAvaNe9IEoCkiQ_P_pP84XYzZaEmNYLiKMJHW0beFCFBIpAifntC9sIowlhbc2cmiNZGwE3Rm-nMGYVq2c-Mg0mpe25E_2yb4OzUsXUM-Ar-FOU6Y0WGO_cwMNhoQXlffndpIRc5sdsuT_gkRBuzHJlT-GtUsQeClkiKxN-_r97kDCmxrD2kR5XmP84Q14jvFAD62YrU-hZccPH8XDSoGvMSlmzYQSmHsdiqz9raKm74eQcVl6djZS8-LPpP2AYpjYdjTzxNfSev9l20tjbL3AY347wQmK2xjRQH5Em9sBcQMLtI7el0IQ3nTywN2ws6STnutBIWNRF_f06-1l1KroR9avlqNsTpyKnr_jXcyJNaLwnWWBeVBu8hTZEpLSVJGyzAjWcvrv0cHYX8Ay-cJCSeRaKplIMSX-Q-5iRzBBpF4LBlCjwuz28EtTPRFuHk_ArlrmPv_S_WfM9u0pX7nesKJDmKK9s1oj0vTMC_6Ofi4FlMwZJcsjMJMwD6FpYIez2-wn7rkt0fpHOlgf-kMx3hMGwAQJcRwnB7bqioOIIAkURoBtUIRYCYkOX2o-LllOY4yf_R1kgajmRxlsFzNqaSOrPhhlpADgFKBk8EUl-rx51p2ph0QUwS3OF_fHCeCwAtr4', getCookieOptions());
//       setCookie('refreshToken', 'def502001c4fee20a33ccc7fe9a0fa8a515ef558167925e72f34d216173f377df951345b105606fa1e788cea5b187d642a1ed0d398e70babe071a499f31eb305afd5f5921478f1eb0ebca36aa52b51af2bff0c7fbc30edcc73a28ce78c63bedc6f9c739b9a0e6d97ba59268ae3fab0ca133336e970899f4d0c1826cdd0a1dd9219ff4b8613910132cfef81e5b880b91e8b19920764a5e55aa3c59a35c355855ff1c4811e73ce0c477d769d3c5d99863595b7abafc74b7dd9395b7bada4ee7fdde801c407bb413a5db1928f30e7cef8233bad5b9bf822b1ab960c018011d4b1b9510e6db311c10fd46f3da289f1e9f59eceff23bc6fe583d1bd25733869aab54b77f0c5c1c9112b75c4df05a3a4c743c58f4620fb47516612c0d07dd6b22e3c3a6918230eb0c6a185eb3d5cb1affc44fd97ffbd0f2e572a6536bc72bcf412905515c7bfd7b885b57f5cdd0b7e55981fba759fc607edb3f072b4833869bef0bd85db31', getCookieOptions());
//       // localStorage.setItem('auth_token', '')
//       window.location.reload();
//     }, 2000)
    
  }

  const mockLogout = () => {
    setPageLoading(true);
    setTimeout(() => {
      // localStorage.removeItem('auth_token')
      removeCookie('authToken', { path: '/', domain: '.etutordev.com' });
      removeCookie('refreshToken', { path: '/', domain: '.etutordev.com' });
      window.location.reload();
    }, 2000)
  }

  const saveSessionSnap = async () => {
    try {
      setSaveSessionLoading(true);

      let snapData = {
        "keyword": lastUsedKeyword,
        "currentKeyword": keyword,
        "prompt": query,
        "subject": buttonName,
        "currentSubject": lastUsedButtonName,
        "generatedText": generatedText,
        "isLandingPage": isLandingPage,
        "references": Array.from(document.querySelectorAll('.refrences-list > div')).map(e => e.innerText),
        "referencesHtml": references,
        "serpSearchResponse": JSON.stringify(serpSearchResponse)
      };

      let data = JSON.stringify({ snapData });

      if(!isAuthenticated) {
        localStorage.setItem('temp-last-saved-snap', JSON.stringify(snapData));
        setShowLoginPrompt(true);
        setLoginPromptReason('Please Login to save your session');
        setSaveSessionLoading(false);
        return;
      }
  
      let config = {
        method: 'post',
        url: `https://plagarism-detection-zcd6zrax3q-uc.a.run.app/textgen/save?userId=${'test-id'}`,
        headers: { 
          'Authorization': `Bearer ${getCookie("authToken") ?? ""}`, 
          'Content-Type': 'application/json'
        },
        data : data
      };
  
      const res = await axios(config);
      // notify saved
      // store sessionId returned
      localStorage.setItem('last-saved-snap-id', res?.data?.snapId);
      localStorage.setItem('last-saved-snap', JSON.stringify(snapData));
      console.log(res.data);
      setSaveSessionLoading(false);
    } catch(error) {
      console.log(error);
      setSaveSessionLoading(false);
    }
  }

  // const setGeneratedText = (value) => {
  //   setGeneratedText(value);
  // }

  // const setCurrentCaretPosition = (value) => {
  //   setCurrentCaretPosition(value);
  // }

  const triggerPlagiarismCheck = () => {
    // const text = query;
    // const result = generatedText;
    setPlagiarismButtonLoading(true);

    let snapData = {
      "keyword": lastUsedKeyword,
      "currentKeyword": keyword,
      "prompt": query,
      "subject": buttonName,
      "currentSubject": lastUsedButtonName,
      "generatedText": generatedText,
      "isLandingPage": isLandingPage,
      "references": Array.from(document.querySelectorAll('.refrences-list > div')).map(e => e.innerText),
      "referencesHtml": references,
      "serpSearchResponse": JSON.stringify(serpSearchResponse)
    };

    let data = JSON.stringify({ snapData });
    
    if(!isAuthenticated) {
      localStorage.setItem('temp-last-saved-snap', JSON.stringify(snapData));
      setShowLoginPrompt(true);
      setLoginPromptReason('Plagarism Checker tool can only be used by registered users, please login to continue');
      setPlagiarismButtonLoading(false);
      return;
    }

    if (!generatedText) {
      console.log("No text");
      setPlagiarismButtonLoading(false);
      return;
      // show some error message?
    }

    let formData = new FormData();
    const blob = new Blob([generatedText], { type: "text/plain" });
    formData.append("submission", blob, `${keyword || 'random_name_tg'}.txt`);

    const reportOptions = JSON.stringify({
      processSafeassign: true,
      processTurnitin: true,
    });

    formData.append("reportOptions", reportOptions);

    axios.post(
      "https://plagarism-detection-zcd6zrax3q-uc.a.run.app/generate",
      formData
    );

    setTimeout(() => {
      setPlagiarismButtonLoading(false);
      window.open(
        "https://plagiarism.etutordev.com/reportList",
        "_blank"
      );
    }, 2000);
  };

  const generateText = (fromInputs) => {
    try {
      getPromptSuggestions();
    } catch(e) {}
    const eventButton = fromInputs?.eventButton; 
    const tries = Number(localStorage.getItem('tg_tries')) || 0;
    if (!isAuthenticated && tries > 25) {
      // TODO Show login popup
      setShowLoginPrompt(true);
      setLoginPromptReason('You have exhausted all free attempts of this tool, please login to continue using eTutorConnect');
      return;
    }

    setReferences([]);

    localStorage.setItem("tg_tries", tries + 1);
    // const url = "http://0.0.0.0:8080/generate";
    // const url = `${window.location.origin}/generate`;
    const url = `https://gpt2-nhry4ngaxq-uc.a.run.app/generate`;

    setGenerate(true);
    setLastUsedQuery(query);
    setLastUsedButtonName(eventButton ?? buttonName);
    setLastUsedKeyword(keyword);
    // setRefrencesLoading(true);

    const form = new FormData();
    form.set("text", query);
    // form.set("size", range);
    // form.set("temperature", temp);
    // form.set("k", k);
    // form.set("p", p);
    form.set("buttonName", eventButton ?? buttonName);
    // form.set("token", token);
    // form.set("email", "lakshagarwala@gmail.com"); // TODO get from state
    // form.set("displayName", "Laxya"); // TODO get from state
    form.set("email", email);
    form.set("displayName", displayName);

    axios
      .post(url, form)
      .then((res) => {
        // setResult(res.data.text);
        setGenerate(false);
        setGeneratedText(res.data.text);
        setIsLandingPage(false);
      })
      .catch((error) => {
        console.log(error);
        setGenerate(false);
      });

      // const oldSerpData = serpSearchResponse;
      // const params = new URLSearchParams(
      //     JSON.parse(
      //         JSON.stringify({
      //             q: keyword,
      //             start: 10
      //         }),
      //     ),
      // ).toString();
      
      // if(keyword !== lastUsedKeyword) {
      //   axios.get(`https://auto-apa-backend-test-1-a3v4rktvya-uc.a.run.app/utility/google-scholar-search?${params}`).then((res) => {
      //     setRefrencesLoading(false);
      //     setSerpSearchResponse(res.data)
      //   }).catch((error) => {
      //     console.log(error);
      //     setSerpSearchResponse(oldSerpData);
      //     setRefrencesLoading(false);
      //   });
      // } else {
      //   setRefrencesLoading(false);
      // }
      // if(keyword !== lastUsedKeyword) {
      //   axios.get(`https://auto-apa-backend-auth-a3v4rktvya-uc.a.run.app/utility/google-scholar-search?${params}`,
      //    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('auth_token')}` } }).then((res) => {
      //     setRefrencesLoading(false);
      //     setSerpSearchResponse(res.data)
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     setSerpSearchResponse(oldSerpData);
      //     setRefrencesLoading(false);
      //   });
      // } else {
      //   setRefrencesLoading(false);
      // }
  };
  const getReferences = (setAreReferencesLoaded, referencesKeyword) => {
    // setRefrencesLoading(true);
    const params = new URLSearchParams(
        JSON.parse(
            JSON.stringify({
                q: referencesKeyword,
                start: 0,
                since: 2019,
            }),
        ),
    ).toString();
    axios.get(`https://auto-apa-backend-test-1-a3v4rktvya-uc.a.run.app/utility/google-scholar-search?${params}`).then((res) => {
      setRefrencesLoading(false);
      setAreReferencesLoaded(true);
      setSerpSearchResponse(res.data)
    }).catch((error) => {
      console.log(error);
      setAreReferencesLoaded(false);
      setRefrencesLoading(false);
    }); 
}

  const regenerateText = () => {
    try {
      getPromptSuggestions();
    } catch(e) {}
    const tries = Number(localStorage.getItem('tg_tries')) || 0;
    if (!isAuthenticated && tries > 25) {
      //Show login popup
      setShowLoginPrompt(true);
      setLoginPromptReason('You have exhausted all free attempts of this tool, please login to continue using eTutorConnect');
      return;
    }
    localStorage.setItem("tg_tries", tries + 1);

    // const url = "http://0.0.0.0:8080/generate";
    // const url = `${window.location.origin}/generate`;
    const url = `https://gpt2-nhry4ngaxq-uc.a.run.app/generate`;

    setReGenerate(true);

    const form = new FormData();
    form.set("text", generatedText);
    // form.set("size", range);
    // form.set("temperature", temp);
    // form.set("k", k);
    // form.set("p", p);
    form.set("buttonName", lastUsedButtonName);
    // form.set("token", token);
    form.set("email", email);
    form.set("displayName", displayName);

    axios
      .post(url, form)
      .then((res) => {
        // setResult(res.data.text);
        setReGenerate(false);
        setGeneratedText(res.data.text);
      })
      .catch((error) => {
        console.log(error);
        setReGenerate(false);
      });
  };
  const getPromptSuggestions = ()=> {
    if(!(query || keyword)) return;
    setGettingSuggestions(true);
    fetch("https://promptssugg-a3v4rktvya-uc.a.run.app/generate_suggestions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ message: query || keyword })
    })
      .then(response => response.json())
      .then(data => {
        const variation = data.response;
        if (variation || variation.length)
          setQuerySuggestions(variation);
          setGettingSuggestions(false);
      })
      .catch(error => {
        console.error("There was an error!", error);
        setGettingSuggestions(false);
      });
  }
  const generateReferences = (setAreReferencesLoaded)=> {
    setRefrencesLoading(true);
    if(!(generatedText || setAreReferencesLoaded)) return;
    fetch("https://promptssugg-a3v4rktvya-uc.a.run.app/generate_references", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ message: generatedText })
    })
      .then(response => response.json())
      .then(data => {
        const referencesKeyword = data.response;
        if (referencesKeyword){
          getReferences(setAreReferencesLoaded, referencesKeyword)
        }
        else {
          const referencesKeyword=keyword
          getReferences(setAreReferencesLoaded, referencesKeyword)
        }
      })
      .catch(error => {
        console.error("There was an error!", error);
        setRefrencesLoading(false);
      });
  }

  const goToAutoApa = async () => {
    setAutoApaButtonLoading(true);

    let snapData = {
      "keyword": lastUsedKeyword,
      "currentKeyword": keyword,
      "prompt": query,
      "subject": buttonName,
      "currentSubject": lastUsedButtonName,
      "generatedText": generatedText,
      "isLandingPage": isLandingPage,
      "references": Array.from(document.querySelectorAll('.refrences-list > div')).map(e => e.innerText),
      "referencesHtml": references,
      "serpSearchResponse": JSON.stringify(serpSearchResponse)
    };

    let data = JSON.stringify({ snapData });
    
    if(!isAuthenticated) {
      localStorage.setItem('temp-last-saved-snap', JSON.stringify(snapData));
      setShowLoginPrompt(true);
      setLoginPromptReason('AutoAPA tool can only be used by registered users, please login to continue');
      setAutoApaButtonLoading(false);
      return;
    }

    let config = {
      method: 'post',
      url: `https://plagarism-detection-zcd6zrax3q-uc.a.run.app/textgen/save?userId=${'test-id'}`,
      headers: { 
        'Authorization': `Bearer ${getCookie("authToken") ?? ""}`, 
        'Content-Type': 'application/json'
      },
      data : data
    };

    const res = await axios(config);
    // notify saved
    // store sessionId returned
    localStorage.setItem('last-saved-snap-id', res?.data?.snapId);
    localStorage.setItem('last-saved-snap', JSON.stringify(snapData));

    setAutoApaButtonLoading(false);
    window.open(`https://autoapa.etutordev.com/editor/?token=${res.data.snapId}`,'_blank');
  }

  const loading = generate ? (
    <div style={{ zIndex: "10", marginBottom: "-120px" }}>
      <Loader type="TailSpin" color="#00FFFF" height={100} width={100} />{" "}
    </div>
  ) : (
    ""
  );

  const handleMenuClick = (button) => {
    setButtonName(button);
    generateText({ eventButton: button });
  };

  const menu = (
    <Menu
      style={{ maxHeight: "50vh" }}
      onClick={async (button) => {
        const selectedItem = filteredNames[button.key];
        if(!selectedItem || selectedItem?.disabled || selectedItem?.type === 'divider' || ! selectedItem?.label)
          return;
        setButtonName(selectedItem?.label);
        handleMenuClick(selectedItem?.label);
      }}
    >
      {filteredNames.map((model, index) => (
        model.type === 'divider' ?
        <Menu.Divider key={index}></Menu.Divider> :
        <Menu.Item key={index} icon={model.icon} disabled={model.disabled}>
          {model.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const searchChange = ({ target: { value } }) => {
    setKeyword(value);
    // const filteredNames = modelNames.filter((modelName) => {
    //   if (modelName.toLowerCase().search(value.toLowerCase()) >= 0) return true;
    //   else return false;
    // });
    // if (!filteredNames.length) {
    //   setFilteredNames(modelNames)
    // } else {
    //   setFilteredNames(filteredNames)
    // }
  };

  if (pageLoading) 
    return (
      <div style={{ zIndex: "10", marginBottom: "-120px", textAlign: "center", position: "relative", padding: "40px" }}>
        <Loader type="TailSpin" color="#4FA8DC" height={100} width={100} />{" "}
        {/* <span>Loading...</span> */}
      </div>
    );

  return (
    <div className="App">
      <Layout className="layout">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <div>
            <div className="main-header">
              <img className="etc-logo" src="/etutorconnect-logo.png" alt="" />
              <a
                className="sign-in-btn"
                href="#"
                onClick={isAuthenticated ? mockLogout : setMockAuthToken }
              >
                {isAuthenticated ? 'Logout' : 'Login' }
              </a>
            </div>
            {/* <Header className="content">
        </Header> */}
            <div className="action-nav-bar bg-primary text-primary-content px-14 py-2 flex justify-between">
              <div className="left-btn-group">
                <Button className="btn btn-sm bg-white text-primary font-semibold border-primary">
                  FAQ
                </Button>
                <Button 
                  className="btn btn-sm bg-white text-primary font-semibold border-primary"
                  onClick={saveSessionSnap}
                  loading={saveSessionLoading}
                >
                  Save
                </Button>
              </div>
              <div className="right-btn-group flex gap-4">
                <Button 
                  className="btn btn-sm bg-white text-primary font-semibold border-primary"
                  onClick={goToAutoApa}
                  loading={autoApaButtonLoading}
                >
                  Auto APA
                </Button>
                <Button
                  className="btn btn-sm bg-white text-primary font-semibold border-primary"
                  onClick={triggerPlagiarismCheck}
                  loading={plagiarismButtonLoading}
                >
                  Plagiarism Checker
                </Button>
                <Button className="dark-action-button btn btn-sm bg-neutral text-white font-semibold">
                  HIRE AN EXPERT
                </Button>
              </div>
            </div>
            {isLandingPage && <LandingPageHero />}
            {/* <div className="site-input-group-wrapper">
              <div className="fields-container">
                <Input
                  style={{ width: "30%", color: "black" }}
                  placeholder="Enter Title, Keyword... (Ex: Big Data)"
                  onChange={searchChange}
                />
                <Input
                  style={{ width: "70%", color: "black" }}
                  placeholder="Write something here related to the topic (Ex: Uses of Big Data)"
                  disabled={generate || !keyword}
                  value={query}
                  onChange={onChange}
                  onFocus={() => setQuerySuggestions(keyword ? mockSuggestions : null)}
                  onBlur={() => setQuerySuggestions(keyword ? mockSuggestions : null)}
                />
              </div>

              <Dropdown.Button
                overlay={menu}
                overlayStyle={{ maxHeight: "80vh", overflowY: "scroll" }}
                disabled={generate || !keyword || !query}
                loading={generate}
                icon={<DownOutlined />}
                type="primary"
                htmlType="submit"
                style={{
                  marginRight: "20px",
                  background: "#4FA8DC",
                  borderRadius: "10px",
                }}
              >
                { buttonName || 'Generate'}
              </Dropdown.Button>
            </div> */}
            {/* <div className="suggestions-area">
              <div className="fields-suggestion-container">
                <div
                  style={{ width: "28%", color: "rgba(0, 0, 0, 0.5)" }}
                  // placeholder="Enter Title, Keyword..."
                  // onChange={searchChange}
                >
                  {querySuggestions ? "" : "Ex: Big Data"}
                </div>
                <div
                  className="main-query-suggestion-list"
                  style={{ width: "72%", color: "rgba(0, 0, 0, 0.5)" }}
                  // placeholder="Write something here related to the topic"
                  // disabled={generate}
                  // value={query}
                  // onChange={onChange}
                >
                  {querySuggestions
                    ? mockSuggestions.map((suggestion, ind) => (
                        <span
                          className="query-suggestion-item"
                          key={ind}
                          onClick={(e) => setQuery(e.target.textContent)}
                        >
                          {suggestion}
                        </span>
                      ))
                    : "Ex: Uses of Big Data"}
                </div>
              </div>
            </div> */}

            <Form
                form={form}
                labelCol={{ span: 24 }}
                onFinish={() => {
                    if (buttonName)
                      generateText()
                }}
                layout="inline"
                style={{ width: '80%', margin: '56px 56px 8px 56px' }}
            >
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                    <Col span={6}>
                        {/* <Input
                          value={keyword}
                          placeholder="Enter Title, Keyword... (Ex: Big Data)"
                          onChange={searchChange}
                        /> */}
                        <Input
                  style={{ padding: '8px 12px', fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  color: '#263238' }}
                  value={keyword}
                  placeholder="Enter Title, Keyword... (Ex: Big Data)"
                  onChange={searchChange}
                  onBlur={getPromptSuggestions}
                />
                    </Col>
                    <Col span={16}>
                        <div>
                            <Tooltip placement="top" title={keyword ? undefined : 'Enter keyword first'}>
                                <div>
                                    <Input.TextArea
                                      placeholder="Write something here related to the topic (Ex: Uses of Big Data)"
                                      disabled={generate || !keyword}
                                      value={query}
                                      onChange={onChange}
                                      // onFocus={() => setQuerySuggestions(keyword ? getPromptSuggestionArray(keyword).sort(() => 0.5 - Math.random()).slice(0, 6) : null)}
                                      autoSize={{ minRows: 1, maxRows: 4 }}
                                      style={{ padding: '8px 12px', fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        color: '#263238' }}
                                    />
                                </div>
                            </Tooltip>
                            {
                          gettingSuggestions ? 
                            (<div style={{ zIndex: "10",  margin: "10px", position: "relative" }}>
                              <Loader type="TailSpin" color="#4FA8DC" height={24} width={24}  />{" "}
                            </div>) 
                            :
                            (<div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                {querySuggestions?.map((suggestion, index) => {
                                    return (
                                        <span
                                            style={{ marginRight: '30px', cursor: 'pointer', color: 'rgba(0,0,0,.5)' }}
                                            onClick={() => setQuery(suggestion)}
                                            key={index}
                                        >
                                            {suggestion}
                                        </span>
                                    );
                                })}
                            </div>)}
                        </div>
                    </Col>
                    <Col span={2}>
                        <Space wrap style={{ alignItems: 'flex-start' }}>
                            <Tooltip
                                placement="top"
                                title={
                                  !keyword || !query ? undefined : 
                                  buttonName ? undefined : 'Select a subject'
                                }
                            >
                                <Dropdown.Button
                overlay={menu}
                overlayStyle={{ maxHeight: "80vh", overflowY: "scroll" }}
                disabled={generate || !keyword || !query}
                loading={generate}
                icon={<DownOutlined />}
                type="primary"
                htmlType="submit"
                size="large"
                shape="round"
                style={{
                  marginRight: "20px",
                  // background: "#4FA8DC",
                  borderRadius: "10px",
                  // padding: "8px",
                  border: "none"
                }}
                className="button-dropdown-generate"
                onClick={() => {
                  if (buttonName)
                    generateText()
                }}
              >
                { buttonName || 'Generate'}
              </Dropdown.Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </Form>

            {/* <GenerateTextDrawer visible={true} setVisibility={() => {}} generateTextDrawerData={null} emailId={email} firstName={displayName} /> */}

            { 
              generate &&
              <div style={{ zIndex: "10", textAlign: "center" }}>
                <Loader type="TailSpin" color="#4FA8DC" height={100} width={100} />{" "}
              </div>
            }

            {!isLandingPage && !generate && (
              <GeneratedText
                generatedText={generatedText}
                setGeneratedText={setGeneratedText}
                currentCaretPosition={currentCaretPosition}
                setCurrentCaretPosition={setCurrentCaretPosition}
                references={references}
                setReferences={setReferences}
                serpSearchResponse={serpSearchResponse}
                refrencesLoading={refrencesLoading}
                generateReferences={generateReferences}
              />
            )}
            {!isLandingPage && !generate && (
              <Button
                style={{
                  margin: "0px 56px",
                  background: "#4FA8DC",
                  borderRadius: "10px",
                }}
                size="large"
                type="primary"
                shape="round"
                disabled={reGenerate}
                loading={reGenerate}
                onClick={regenerateText}
              >
                Continue Generating
              </Button>
            )}
            <Content
              style={{
                // minHeight: "100vh",
                paddingTop: "10px",
                marginBottom: "10px",
              }}
            ></Content>
            {isLandingPage && <ValueAddDescriptors />}
            {isLandingPage && <FeatureList />}
            <LoginModal
              isModalOpen={showLoginPrompt}
              setIsModalOpen={setShowLoginPrompt}
              modalMessage={loginPromptReason}
            />        
          </div>
        </div>
      </Layout>
    </div>
  );
};
