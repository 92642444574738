import React, { Component } from "react";
import "./App.css";
import {
  Layout,
  Typography,
  Input,
  Slider,
  InputNumber,
  Row,
  Col,
  Button,
  message,
  AutoComplete,
  Select,
  Modal
} from "antd";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import { Menu, Dropdown } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { LandingPageHero } from './components/LandingPageHero'
import { ValueAddDescriptors } from './components/ValueAddDescriptors'
import { FeatureList } from './components/FeatureList'
import { GeneratedText } from './components/GeneratedText'
// import firebase from "firebase";

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyBxITmq5dW-TWklvzaqYsr5TnraODhiMZU",
//   authDomain: "gpt2deployment.firebaseapp.com",
// });

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const { Header, Content, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const FormData = require("form-data");
const { Option } = Select;

const mockSuggestions = [
  'Cons of Big Data Handling?',
  'Advantages of Big Data Handling?',
  'Who invented the term - Big Data?',
  'Nature of Big Data',
  'Cons of Big Data Handling?',
  'Advantages of Big Data Handling?',
  'Who invented the term - Big Data?',
  'Nature of Big Data',
  'What are the uses of Big Data Handling?'
];

var jwt = require('jsonwebtoken');

class App extends Component {
  constructor(props) {
    super(props);

    let authToken = localStorage.getItem('auth_token') ?? '';
    let isAuthenticated = false;
    if (authToken) {
      try{
        const decodedToken = jwt.decode(authToken, { complete: true });
        if (decodedToken) 
          isAuthenticated = true;
        else
          isAuthenticated = false;
      } catch (e) {
        isAuthenticated = false;
      }
    }

    this.state = {
      result: "",
      generate: false,
      range: 200, // length
      temp: 1,
      k: 50,
      p: 0.95,
      wordCount: 0,
      query: "",
      buttonName: "",
      searchQuery: "",
      //Add different models here
      modelNames: [
        "General_A",
        "General_B",
        "General_C",
        "General_D",
        "Emerging Threats",
        "Business Intelligence",
        "Security Architecture",
        "Org Behaviour & Leadership",
        "Managerial Economics",
        "Enterprise Risk Management",
        "Project Management",
        "Managerial Accounting",
        "Marketing Communication",
        "Marketing Management",
        "Big Data",
        "Cloud Computing",
        "Managerial Finance",
        "Cryptography",
        "Busi. Continuity & Disaster Plan",
        "Access Control",
        "Adv. Programming Languages",
        "Adv. Computer Networking",
        "Blockchain & Cryptocurrency",
        "C&E",
        "Cyber Law",
        "Cyber Security",
        "Foundations",
        "Physical Security",
        "Global Policies",
        "Health Finance",
        "Information Governance",
        "ITM Capstone",
        "Management Info System",
        "Operational Excellence",
        "Business Ethics",
        "Information Systems",
        "Negotiation",
        "Info. Security & Risk Mgmt",
        "Telecom Security",
        "Intro to Data Mining",
        "Application Security",
        "Team Management",
        "Legal",
        "Business Sustain. & Analysis",
        "Initiating the Project",
        "Operation Security"
      ].sort(),
      //default model name
      filteredNames: [
        "General_A",
        "General_B",
        "General_C",
        "General_D",
        "Emerging Threats",
        "Business Intelligence",
        "Security Architecture",
        "Org Behaviour & Leadership",
        "Managerial Economics",
        "Enterprise Risk Management",
        "Project Management",
        "Managerial Accounting",
        "Marketing Communication",
        "Marketing Management",
        "Big Data",
        "Cloud Computing",
        "Managerial Finance",
        "Cryptography",
        "Busi. Continuity & Disaster Plan",
        "Access Control",
        "Adv. Programming Languages",
        "Adv. Computer Networking",
        "Blockchain & Cryptocurrency",
        "C&E",
        "Cyber Law",
        "Cyber Security",
        "Foundations",
        "Physical Security",
        "Global Policies",
        "Health Finance",
        "Information Governance",
        "ITM Capstone",
        "Management Info System",
        "Operational Excellence",
        "Business Ethics",
        "Information Systems",
        "Negotiation",
        "Info. Security & Risk Mgmt",
        "Telecom Security",
        "Intro to Data Mining",
        "Application Security",
        "Team Management",
        "Legal",
        "Business Sustain. & Analysis",
        "Initiating the Project",
        "Operation Security"
      ].sort(),
      token: "",
      email: "",
      displayName: "",
      generatedText: "",
      isLandingPage: true,
      querySuggestions: null,
      currentCaretPosition: 0,
      isAuthenticated,
      showLoginPrompt: false,
    };

    this.origin = window.location.origin;
  }

  onChange = ({ target: { value } }) => {
    this.setState({ query: value });
  };

  showLoginPrompt = () => {
    this.setState({ showLoginPrompt: true });
  };
  
  hideLoginPrompt = () => {
    this.setState({ showLoginPrompt: false });
  };

  setGeneratedText = (value) => {
    this.setState({ generatedText: value })
  }

  setCurrentCaretPosition = (value) => {
    this.setState({ currentCaretPosition: value })
  }

  triggerPlagiarismCheck = () => {
    const text = this.state.query;
    const result = this.state.result;
    if (!text && !result) {
      console.log('No text')
      return;
      // show some error message?
    }

    let formData = new FormData();
    const blob = new Blob([result ?? text], { type: "text/plain" });
    formData.append("submission", blob, "random_name.txt");

    const reportOptions = JSON.stringify({
      processSafeassign: true,
      processTurnitin: true,
    });

    formData.append("reportOptions", reportOptions);

    axios.post(
      "https://plagarism-detection-zcd6zrax3q-uc.a.run.app/generate",
      formData
    );

    setTimeout(() => {
      window.open('https://golden-yeot-40fb5e.netlify.app/reportList','_blank');
    }, 2000);
  }

  generateText() {
    const tries = Number(localStorage.getItem('tg_tries')) || 0;
    if (tries > 25) {
      //Show login popup
      return;
    }
    localStorage.setItem('tg_tries', tries + 1);
    var text = this.state.query;
    // const url = "http://0.0.0.0:8080/generate";
    // const url = `${this.origin}/generate`;
    const url = `https://gpt2-nhry4ngaxq-uc.a.run.app/generate`;

    if (parseInt(this.state.wordCount) + this.state.range >= 1022) {
      message.error(
        `Word limit(1023) Exceeded, only max of ${1021 - this.state.wordCount
        } more can be added or Please write a new query`,
        5
      );
    }

    this.setState({ ...this.state, generate: true });

    const form = new FormData();
    form.set("text", text);
    form.set("size", this.state.range);
    form.set("temperature", this.state.temp);
    form.set("k", this.state.k);
    form.set("p", this.state.p);
    form.set("buttonName", this.state.buttonName);
    form.set("token", this.state.token);
    form.set("email", 'lakshagarwala@gmail.com');
    form.set("displayName", 'Laxya');

    axios
      .post(url, form)
      .then((res) => {
        this.setState({
          result: res.data.text,
          generate: false,
          wordCount: res.data.wordcount,
          generatedText: res.data.text, 
          isLandingPage: false
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ generate: false });
      });
  }

  regenerateText() {
    const tries = Number(localStorage.getItem('tg_tries')) || 0;
    if (tries > 25) {
      //Show login popup
      return;
    }
    localStorage.setItem('tg_tries', tries + 1);

    // const url = "http://0.0.0.0:8080/generate";
//     const url = `${this.origin}/generate`;
    const url = `https://gpt2-nhry4ngaxq-uc.a.run.app/generate`;
    if (parseInt(this.state.wordCount) + this.state.range >= 1022) {
      message.error(
        `Word limit(1023) Exceeded, only max of ${1021 - this.state.wordCount
        } more can be added or Please write a new query`,
        5
      );
    }

    this.setState({ generate: true });

    const form = new FormData();
    form.set("text", this.state.result);
    form.set("size", this.state.range);
    form.set("temperature", this.state.temp);
    form.set("k", this.state.k);
    form.set("p", this.state.p);
    form.set("buttonName", this.state.buttonName);
    form.set("token", this.state.token);
    form.set("email", this.state.email);
    form.set("displayName", this.state.displayName);

    axios
      .post(url, form)
      .then((res) => {
        this.setState({
          result: res.data.text,
          generate: false,
          wordCount: res.data.wordcount,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ generate: false });
      });
  }

  changeRange = (value, key) => {
    if (key === "temp")
      this.setState({
        ...this.state,
        temp: value,
      });
    else if (key === "k")
      this.setState({
        ...this.state,
        k: value,
      });
    else if (key === "p")
      this.setState({
        ...this.state,
        p: value,
      });
    else
      this.setState({
        ...this.state,
        range: value,
      });
  };

  render() {
    const results = this.state.result ? (
      <Content
        style={{
          background: "white",
          overflow: "auto",
          borderRadius: "20px 10px",
          padding: "0px 20px",
        }}
        className="card"
      >
        <Paragraph copyable={true}>{this.state.result}</Paragraph>
      </Content>
    ) : (
      ""
    );

    const loading = this.state.generate ? (
      <div style={{ zIndex: "10", marginBottom: "-120px" }}>
        <Loader type="TailSpin" color="#00FFFF" height={100} width={100} />{" "}
      </div>
    ) : (
      ""
    );

    const handleMenuClick = (button) => {
      this.setState({
        ...this.state,
        buttonName: button.key,
      });
      this.generateText();
    };

    const menu = (
      <Menu
        onClick={async (button) => {
          await this.setState({
            ...this.state,
            buttonName: button.key,
          });
          handleMenuClick(button);
        }}
      >
        {this.state.filteredNames.map((model) => (
          <Menu.Item key={model} icon={<UserOutlined />}>
            {model}
          </Menu.Item>
        ))}
      </Menu>
    );

    const searchChange = ({ target: { value } }) => {
      this.setState({ ...this.state, buttonName: value, querySuggestions: value ? mockSuggestions : null });
      const filteredNames = this.state.modelNames.filter((modelName) => {
        if (modelName.toLowerCase().search(value.toLowerCase()) >= 0)
          return true;
        else return false;
      });
      // if (!filteredNames.length) {
      //   this.setState({
      //     ...this.state,
      //     filteredNames: this.state.modelNames,
      //   });
      // } else {
      //   this.setState({
      //     ...this.state,
      //     filteredNames: filteredNames,
      //   });
      // }
    };

    // const signIn = () => {
    //   const provider = new firebase.auth.GoogleAuthProvider();
    //   provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then(async (result) => {
    //       // Returns the signed in user along with the provider's credential
    //       console.log(`${result.user.displayName} logged in.`);
    //       //window.alert(`Welcome ${result.user.displayName}!`);
    //       const token = await firebase.auth().currentUser.getIdToken();
    //       const email = await firebase.auth().currentUser.email;
    //       const displayName = await firebase.auth().currentUser.displayName;
    //       this.setState({
    //         ...this.state,
    //         token,
    //         email,
    //         displayName,
    //       });
    //       console.log({ token, email, displayName });
    //     })
    //     .catch((err) => {
    //       console.log(`Error during sign in: ${err.message}`);
    //     });
    // };

    // if (!this.state.token)
    //   return (
    //     <div className="App">
    //       <Layout className="layout">
    //         <Header className="content">
    //           <Text style={{ color: "#00FFFF" }}>Cherish Technologies INC</Text>
    //         </Header>
    //         <Content
    //           style={{
    //             minHeight: "100vh",
    //             paddingTop: "10px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           <Title>Text Generation Query Bot</Title>
    //           <Button size="large" type="primary" onClick={signIn}>
    //             Sign In With Google
    //           </Button>
    //         </Content>
    //       </Layout>
    //     </div>
    //   );

    return (
      <div className="App">
        <Layout className="layout">
          <div style={{
            display: "flex",
            "flexDirection": "column",
            "justifyContent": "space-between",
            height: "100vh"
          }}>
            <div>
              <div className="main-header">
                <img className="etc-logo" src="/etutorconnect-logo.png" alt=""/>
                <a className="sign-in-btn" href="#" onClick={this.showLoginPrompt}>Sign In</a>
              </div>
              {/* <Header className="content">
              </Header> */}
              <div className="action-nav-bar bg-primary text-primary-content px-14 py-2 flex justify-between">
                <div className="left-btn-group">
                  <button className="btn btn-sm bg-white text-primary font-semibold border-primary">
                    FAQ
                  </button>
                </div>
                <div className="right-btn-group flex gap-4">
                  <button className="btn btn-sm bg-white text-primary font-semibold border-primary">
                    Auto APA
                  </button>
                  <button 
                    className="btn btn-sm bg-white text-primary font-semibold border-primary"
                    onClick={this.triggerPlagiarismCheck}
                  >
                    Plagiarism Checker
                  </button>
                  <button className="dark-action-button btn btn-sm bg-neutral text-white font-semibold">
                    HIRE AN EXPERT
                  </button>
                </div>
              </div>
              
              {this.state.isLandingPage && <LandingPageHero />}
              <div className="site-input-group-wrapper">
              {/* <div> */}
                <div className="fields-container">
                  <Input 
                    style={{ width: '30%', color: 'black' }}
                    placeholder="Enter Title, Keyword..."
                    onChange={searchChange}
                  />
                  {/* <div className="separation-line"></div> */}
                  <Input 
                    style={{ width: '70%', color: 'black' }}
                    placeholder="Write something here related to the topic"
                    disabled={this.state.generate}
                    value={this.state.query}
                    onChange={this.onChange}
                  />
                </div>

                {/* <Select defaultValue="Zhejiang">
                  <Option value="Zhejiang">Zhejiang</Option>
                  <Option value="Jiangsu">Jiangsu</Option>
                </Select> */}
                <Dropdown overlay={menu} overlayStyle={{ maxHeight: '80vh', overflowY: 'scroll' }} >
                  <Button
                    style={{ marginRight: "20px", background: '#4FA8DC', borderRadius: '10px' }}
                    size="large"
                    type="primary"
                    shape="round"

                    disabled={this.state.generate}
                    loading={this.state.generate}
                  >
                    Generate <DownOutlined />
                  </Button>
                </Dropdown>
              {/* </div> */}
              </div>
              <div className="suggestions-area">
                <div className="fields-suggestion-container">
                  <div 
                    style={{ width: '28%', color: 'rgba(0, 0, 0, 0.5)' }}
                    // placeholder="Enter Title, Keyword..."
                    // onChange={searchChange}
                  >{this.state.querySuggestions ? '' : 'Ex: Big Data'}</div>
                  {/* <div className="separation-line"></div> */}
                  <div 
                    className="main-query-suggestion-list"
                    style={{ width: '72%', color: 'rgba(0, 0, 0, 0.5)' }}
                    // placeholder="Write something here related to the topic"
                    // disabled={this.state.generate}
                    // value={this.state.query}
                    // onChange={this.onChange}
                  >
                    {this.state.querySuggestions ? mockSuggestions.map((suggestion, ind) => <span 
                      className="query-suggestion-item" 
                      key={ind}
                      onClick={(e) => this.setState({ query: e.target.textContent })}
                      >
                        {suggestion}
                      </span>)
                      : 'Ex: Uses of Big Data'}</div>
                </div>
              </div>
              {!this.state.isLandingPage &&
                <GeneratedText 
                  generatedText={this.state.generatedText} 
                  setGeneratedText={this.setGeneratedText} 
                  currentCaretPosition={this.state.currentCaretPosition}
                  setCurrentCaretPosition={this.setCurrentCaretPosition}
                />
              }
              <Content
                style={{
                  // minHeight: "100vh",
                  paddingTop: "10px",
                  marginBottom: "10px",
                }}
              >

                {/* <Title>Text Generation Query Bot</Title> */}
                {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <Row
                    style={{
                      display: "flex",
                      width: "80%",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Col style={{ display: "flex", flexBasis: "60%" }}>
                      <TextArea
                        autoSize={{ minRows: 2 }}
                        placeholder="Write Something here"
                        allowClear={true}
                        style={{ width: "100%" }}
                        disabled={this.state.generate}
                        value={this.state.query}
                        onChange={this.onChange}
                      />
                    </Col>
                    <Col>
                      <Input
                        style={{
                          height: "40px",
                          width: "120px",
                          borderRadius: "25px",
                          marginLeft: "5px",
                        }}
                        placeholder="Search Subject"
                        onChange={searchChange}
                      />
                    </Col>
                    <Col>
                      <Dropdown overlay={menu} overlayStyle={{ maxHeight: '80vh', overflowY: 'scroll' }} >
                        <Button
                          style={{ marginLeft: "5px", background: '#4FA8DC', 'border-radius': '10px' }}
                          size="large"
                          type="primary"
                          shape="round"
                          disabled={this.state.generate}
                          loading={this.state.generate}
                        >
                          Generate <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </div> */}


                {/* <Button
                  size="large"
                  type="primary"
                  shape="round"
                  onClick={() => this.generateText()}
                  style={{ position: "absolute" }}
                  disabled={this.state.generate}
                  loading={this.state.generate}
                >
                  Generate Text
                </Button> */}

                {/* <Row
                  style={{
                    marginLeft: "2%",
                    marginTop: "4%",
                    maxWidth: "96%",
                    paddingTop: "1px",
                    paddingLeft: "30px",
                  }}
                >
                  <Col
                    span={5}
                    style={{
                      background: "white",
                      height: "350px",
                      paddingTop: "2%",
                      paddingBottom: "2%",
                      borderRadius: "5px",
                    }}
                    className="card"
                  >
                    <Row>
                      <Col span={24}>
                        <div style={{ float: "center" }} className="content">
                          Length to Generate:
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4}></Col>
                      <Col span={16}>
                        <Slider
                          style={{ float: "center" }}
                          min={1}
                          defaultValue={200}
                          max={300}
                          onChange={(val) => this.changeRange(val)}
                          value={
                            typeof this.state.range === "number"
                              ? this.state.range
                              : 0
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={7}></Col>
                      <Col span={10}>
                        <InputNumber
                          min={1}
                          max={300}
                          style={{ margin: "0 16px" }}
                          value={this.state.range}
                          onChange={(val) => this.changeRange(val)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div
                          style={{ float: "center", marginTop: "15px" }}
                          className="content"
                        >
                          Temperature
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4}></Col>
                      <Col span={16}>
                        <Slider
                          style={{ float: "center" }}
                          min={0}
                          step={0.01}
                          max={3}
                          defaultValue={this.state.temp}
                          onChange={(val) => this.changeRange(val, "temp")}
                          value={
                            typeof this.state.temp === "number"
                              ? this.state.temp
                              : 0
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={7}></Col>
                      <Col span={10}>
                        <InputNumber
                          min={0}
                          max={3}
                          style={{ margin: "0 16px" }}
                          value={this.state.temp}
                          onChange={(val) => this.changeRange(val, "temp")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div
                          style={{ float: "center", marginTop: "15px" }}
                          className="content"
                        >
                          Top_P
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4}></Col>
                      <Col span={16}>
                        <Slider
                          style={{ float: "center" }}
                          min={0}
                          step={0.01}
                          defaultValue={0.95}
                          max={1}
                          onChange={(val) => this.changeRange(val, "p")}
                          value={
                            typeof this.state.p === "number" ? this.state.p : 0
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={7}></Col>
                      <Col span={10}>
                        <InputNumber
                          min={0}
                          max={1}
                          style={{ margin: "0 16px" }}
                          value={this.state.p}
                          onChange={(val) => this.changeRange(val, "p")}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={12} className="content">
                    {loading}
                    {results}
                  </Col>
                  <Col span={1}></Col>
                  <Col span={3}>
                    {this.state.wordCount <= 1022 && this.state.wordCount > 0 ? (
                      <Button
                        type="default"
                        shape="round"
                        size="large"
                        onClick={() => this.regenerateText()}
                        disabled={this.state.generate}
                      >
                        Continue Generating
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={2}></Col>
                </Row> */}

              </Content>
              {this.state.isLandingPage && <ValueAddDescriptors />}
              {this.state.isLandingPage && <FeatureList />}
              <Modal title="Basic Modal" open={this.state.showLoginPrompt} onOk={this.showLoginPrompt} onCancel={this.hideLoginPrompt}>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
            // </div>
            // <div>
             // <Footer className="footer">
               // Copyright &copy; {new Date().getFullYear()} Cherish Technologies INC
             // </Footer>
           // </div>
          // </div>
        </Layout>
      </div>
    );
  }
}

export default App;
