// import dotenv from 'dotenv';

// dotenv.config();

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getCookieOptions = () => {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1)
  const cookieOptions = {
    expires: expirationDate,
    // domain: 'golden-yeot-40fb5e.netlify.app',
    // domain: '127.0.0.1',
    domain: '.etutordev.com',
    // domain: process.env.ENVIRONMENT_FLAG ? process.env.ETC_DEPLOYMENT_DOMAIN : process.env.ETC_DEPLOYMENT_DOMAIN,
    httpOnly: false,
  }
  return cookieOptions;
}
