import React, { Component } from "react";

export const ValueAddDescriptors = () => <div className="value-add-descriptors">
<div className="descriptor-card">
  <img src="./git-box-icon.png" alt=""/>
  <div className="content">
    <span className="vad-title">Free Content Creation</span>
    <span className="vad-body">Generate content and Save your asssignments at $0.</span>
  </div>
</div>
<div className="descriptor-card">
  <img src="./new-user-icon.png" alt=""/>
  <div className="content">
    <span className="vad-title">Hire an Expert</span>
    <span className="vad-body">Hire and expert to help with your assignments.</span>
  </div>
</div>
<div className="descriptor-card">
  <img src="./task-done-icon.png" alt=""/>
  <div className="content">
    <span className="vad-title">Flexible schedule</span>
    <span className="vad-body">You can chat with the expert anytime, 24/7</span>
  </div>
</div>
</div>