import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import "../LoginModal.css";
export const LoginModal = ({ modalMessage, isModalOpen, setIsModalOpen,  }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal 
        title={
          <span style={{ textAlign: "center", display: "block" }}>
            <img className="etc-logo" src="/etutorconnect-logo.png" alt="" />
          </span >
        } 
        visible={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        centered={true} 
        confirmLoading={false}
        cancelText="Skip"
        okText="Login"
        // footer={null}
        // mask={false}
      >
        {/* <img className="etc-logo" src="/etutorconnect-logo.png" alt="" /> */}

        <p style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '27px',
          textAlign: "center"
        }}>Login Required</p>
        <p style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '27px',
          textAlign: "center"
        }}>{modalMessage}</p>
      </Modal>
    </>
  );
};
