import React, { Component } from "react";

export const LandingPageHero = () => <div className="hero-container">
<div className="left-section">
  <span className="huge-text tagline">AI-Text Generator built specifically for Students</span>
  <span className="large-text description">Prepare your assignments which are free of plagiarism in a few clicks.</span>
</div>
<div className="right-section">
  <img className="fileSearching" src="./file-searching.png" alt=""/>
</div>
</div>